import { useQuery } from 'react-query';
import { SiteApi } from 'swagger/apis/site-api';
import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { PublicApi } from 'swagger/apis/public-api';

const siteApi = new SiteApi(OpenApiConfiguration);
const publicApi = new PublicApi(OpenApiConfiguration);

export const useGetSideDataQuery = () =>
  useQuery(['siteData'], () => siteApi.domesticV2SiteGet(), {
    refetchOnWindowFocus: false,
  });

export const useGetFeatureFlagsQuery = () => {
  const { data: { data: featureFlags } = {}, ...rest } = useQuery(['featureFlags'], () =>
    publicApi.domesticV2FlagsGet()
  );

  return {
    featureFlags,
    ...rest,
  };
};

export const useGetFeatureFlagByName = (flagName: string) => {
  const { data: { data: featureFlag } = {} } = useQuery(
    [flagName],
    () => publicApi.domesticV2FlagsFlagNameGet(flagName),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );

  return featureFlag?.[flagName] || false;
};

export const useFeatureFlagQueryByNameWithAnonymousID = (flagName: string) => {
  const { data, isLoading } = useQuery(
    [flagName, 'anonymous'],
    () => publicApi.domesticV2FlagsFlagNameGet(flagName, true),
    {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    }
  );

  const featureFlag = data?.data?.[flagName] || false;

  return { featureFlag, isLoading };
};

export const useFeatureFlagQueryByName = (flagName: string) => {
  const { data, isLoading, error } = useQuery([flagName], () => publicApi.domesticV2FlagsFlagNameGet(flagName), {
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
  });

  const featureFlag = data?.data;
  const flag = featureFlag?.[flagName] ?? false;

  return { flag, isLoading, error };
};
