/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Boat } from '../models';
// @ts-ignore
import { PublicUser } from '../models';
// @ts-ignore
import { Review } from '../models';
/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} flagName 
         * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2FlagsFlagNameGet: async (flagName: string, anonymous?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'flagName' is not null or undefined
            assertParamExists('domesticV2FlagsFlagNameGet', 'flagName', flagName)
            const localVarPath = `/domestic/v2/flags/{flagName}`
                .replace(`{${"flagName"}}`, encodeURIComponent(String(flagName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (anonymous !== undefined) {
                localVarQueryParameter['anonymous'] = anonymous;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2FlagsGet: async (anonymous?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (anonymous !== undefined) {
                localVarQueryParameter['anonymous'] = anonymous;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdBoatsGet: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('domesticV2UsersPublicIdBoatsGet', 'publicId', publicId)
            const localVarPath = `/domestic/v2/users/{publicId}/boats`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdGet: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('domesticV2UsersPublicIdGet', 'publicId', publicId)
            const localVarPath = `/domestic/v2/users/{publicId}`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdReviewsGet: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('domesticV2UsersPublicIdReviewsGet', 'publicId', publicId)
            const localVarPath = `/domestic/v2/users/{publicId}/reviews`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} flagName 
         * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2FlagsFlagNameGet(flagName: string, anonymous?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2FlagsFlagNameGet(flagName, anonymous, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2FlagsGet(anonymous?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2FlagsGet(anonymous, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2UsersPublicIdBoatsGet(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Boat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2UsersPublicIdBoatsGet(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2UsersPublicIdGet(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2UsersPublicIdGet(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2UsersPublicIdReviewsGet(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2UsersPublicIdReviewsGet(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @param {string} flagName 
         * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2FlagsFlagNameGet(flagName: string, anonymous?: boolean, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.domesticV2FlagsFlagNameGet(flagName, anonymous, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2FlagsGet(anonymous?: boolean, options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.domesticV2FlagsGet(anonymous, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdBoatsGet(publicId: string, options?: any): AxiosPromise<Boat> {
            return localVarFp.domesticV2UsersPublicIdBoatsGet(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdGet(publicId: string, options?: any): AxiosPromise<PublicUser> {
            return localVarFp.domesticV2UsersPublicIdGet(publicId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2UsersPublicIdReviewsGet(publicId: string, options?: any): AxiosPromise<Review> {
            return localVarFp.domesticV2UsersPublicIdReviewsGet(publicId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - interface
 * @export
 * @interface PublicApi
 */
export interface PublicApiInterface {
    /**
     * 
     * @param {string} flagName 
     * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    domesticV2FlagsFlagNameGet(flagName: string, anonymous?: boolean, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: boolean; }>;

    /**
     * 
     * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    domesticV2FlagsGet(anonymous?: boolean, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: boolean; }>;

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    domesticV2UsersPublicIdBoatsGet(publicId: string, options?: AxiosRequestConfig): AxiosPromise<Boat>;

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    domesticV2UsersPublicIdGet(publicId: string, options?: AxiosRequestConfig): AxiosPromise<PublicUser>;

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    domesticV2UsersPublicIdReviewsGet(publicId: string, options?: AxiosRequestConfig): AxiosPromise<Review>;

}

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI implements PublicApiInterface {
    /**
     * 
     * @param {string} flagName 
     * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public domesticV2FlagsFlagNameGet(flagName: string, anonymous?: boolean, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).domesticV2FlagsFlagNameGet(flagName, anonymous, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [anonymous] When true uses anonymous user as flag actor instead of current_user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public domesticV2FlagsGet(anonymous?: boolean, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).domesticV2FlagsGet(anonymous, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public domesticV2UsersPublicIdBoatsGet(publicId: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).domesticV2UsersPublicIdBoatsGet(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public domesticV2UsersPublicIdGet(publicId: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).domesticV2UsersPublicIdGet(publicId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public domesticV2UsersPublicIdReviewsGet(publicId: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).domesticV2UsersPublicIdReviewsGet(publicId, options).then((request) => request(this.axios, this.basePath));
    }
}
