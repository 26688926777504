import styled from 'styled-components';

const getCircleSize = (size) => {
  switch (size) {
    case 'large':
      return '25';
    case 'medium':
      return '22';
    case 'small':
      return '13';
    default:
      return '13';
  }
};

export default function LoadingSpinner({
  color = '#ffffff',
  size = 'small',
  bg = true,
  className = '',
  addSeparator = false,
}) {
  const circleSize = getCircleSize(size);
  return (
    <Container className={className}>
      {size === 'small' && addSeparator && <Separator />}
      <svg className={`LoadingSpinner--${size} animate`}>
        <circle
          className={`LoadingSpinner-circle  animate ${size}`}
          style={{ stroke: `${color}` }}
          cx={circleSize}
          cy={circleSize}
          r={circleSize}
        />
      </svg>
      {bg && (
        <svg className={`LoadingSpinner--${size} LoadingSpinner-bgWrapper`}>
          <circle
            className={`LoadingSpinner-circle  LoadingSpinner-bg ${size}`}
            cx={circleSize}
            cy={circleSize}
            r={circleSize}
          />
        </svg>
      )}
    </Container>
  );
}

const Container = styled.span`
  position: relative;
`;

const Separator = styled.div`
  display: inline-block;
  /* margin-right: 8px; */
`;
