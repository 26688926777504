/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Booking } from '../models';
// @ts-ignore
import { BookingActions } from '../models';
// @ts-ignore
import { BookingFuelReimbursement } from '../models';
// @ts-ignore
import { BookingModification } from '../models';
// @ts-ignore
import { BookingPricing } from '../models';
// @ts-ignore
import { ConflictError } from '../models';
// @ts-ignore
import { DomesticV2BookingsCheckoutPostRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdAboardPatchRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdApprovalPostRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdAssignCaptainPost200Response } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdAssignCaptainPost409Response } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdAssignCaptainPostRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdCalculatorGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdCancellationRefundPercentGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdCaptainDeclinePostRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdCheckoutPostRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdInquiryPatchRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdMessagesGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdMessagesGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdMessagesGetRequest1 } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdModificationsGetRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdNearCaptainsGet200ResponseInner } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdPassengerShortCodesPost200Response } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdPreApprovalDeleteRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdPrerequisitesGet200Response } from '../models';
// @ts-ignore
import { DomesticV2BookingsIdReviewsPostRequest } from '../models';
// @ts-ignore
import { DomesticV2BookingsInquiryPostRequest } from '../models';
// @ts-ignore
import { SharedDetails } from '../models';
/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Checkout for the provided @Booking@ parameter set iff the client has the appropriate state to do so. A credit-card-id is expected to provide a means for authorising a payment. 
         * @summary Create a checkout
         * @param {DomesticV2BookingsCheckoutPostRequest} [body] Booking Checkout JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsCheckoutPost: async (body?: DomesticV2BookingsCheckoutPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/bookings/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} packageId 
         * @param {string} duration 
         * @param {string} tripStart 
         * @param {string} tripFinish 
         * @param {string} tripTime 
         * @param {string} [bookingId] 
         * @param {number} [passengers] 
         * @param {boolean} [purchasingInsurance] Required if package is using Buoy insurance
         * @param {string} [insuranceRenterPolicyId] Required if package is using Buoy insurance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsCheckoutPrerequisitesGet: async (packageId: string, duration: string, tripStart: string, tripFinish: string, tripTime: string, bookingId?: string, passengers?: number, purchasingInsurance?: boolean, insuranceRenterPolicyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageId' is not null or undefined
            assertParamExists('domesticV2BookingsCheckoutPrerequisitesGet', 'packageId', packageId)
            // verify required parameter 'duration' is not null or undefined
            assertParamExists('domesticV2BookingsCheckoutPrerequisitesGet', 'duration', duration)
            // verify required parameter 'tripStart' is not null or undefined
            assertParamExists('domesticV2BookingsCheckoutPrerequisitesGet', 'tripStart', tripStart)
            // verify required parameter 'tripFinish' is not null or undefined
            assertParamExists('domesticV2BookingsCheckoutPrerequisitesGet', 'tripFinish', tripFinish)
            // verify required parameter 'tripTime' is not null or undefined
            assertParamExists('domesticV2BookingsCheckoutPrerequisitesGet', 'tripTime', tripTime)
            const localVarPath = `/domestic/v2/bookings/checkout_prerequisites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (bookingId !== undefined) {
                localVarQueryParameter['booking_id'] = bookingId;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['package_id'] = packageId;
            }

            if (passengers !== undefined) {
                localVarQueryParameter['passengers'] = passengers;
            }

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (tripStart !== undefined) {
                localVarQueryParameter['trip_start'] = tripStart;
            }

            if (tripFinish !== undefined) {
                localVarQueryParameter['trip_finish'] = tripFinish;
            }

            if (tripTime !== undefined) {
                localVarQueryParameter['trip_time'] = tripTime;
            }

            if (purchasingInsurance !== undefined) {
                localVarQueryParameter['purchasing_insurance'] = purchasingInsurance;
            }

            if (insuranceRenterPolicyId !== undefined) {
                localVarQueryParameter['insurance_renter_policy_id'] = insuranceRenterPolicyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Start booking trip
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdAboardPatch: async (id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdAboardPatch', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/aboard`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Booking approval
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdApprovalPostRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdApprovalPost: async (id: string, body?: DomesticV2BookingsIdApprovalPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdApprovalPost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/approval`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * End booking trip
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdAshorePatch: async (id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdAshorePatch', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/ashore`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Assigns captain to the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAssignCaptainPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdAssignCaptainPost: async (id: string, body: DomesticV2BookingsIdAssignCaptainPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdAssignCaptainPost', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('domesticV2BookingsIdAssignCaptainPost', 'body', body)
            const localVarPath = `/domestic/v2/bookings/{id}/assign_captain`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recalculate existing booking. For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
         * @param {string} id Booking ID
         * @param {string} [tripStart] ISO 8601 date
         * @param {string} [tripFinish] ISO 8601 date
         * @param {number} [passengers] 
         * @param {number} [specialOfferPrice] Special offer price in cents
         * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
         * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
         * @param {Array<string>} [addons] Array of objects currently not supported, instead of string insert object with boat_add_on_id (string), quantity (number)
         * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
         * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
         * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCalculatorGet: async (id: string, tripStart?: string, tripFinish?: string, passengers?: number, specialOfferPrice?: number, couponCode?: string, insuranceRenterPolicyId?: string, addons?: Array<string>, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdCalculatorGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/calculator`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)

            if (tripStart !== undefined) {
                localVarQueryParameter['trip_start'] = tripStart;
            }

            if (tripFinish !== undefined) {
                localVarQueryParameter['trip_finish'] = tripFinish;
            }

            if (passengers !== undefined) {
                localVarQueryParameter['passengers'] = passengers;
            }

            if (specialOfferPrice !== undefined) {
                localVarQueryParameter['special_offer_price'] = specialOfferPrice;
            }

            if (couponCode !== undefined) {
                localVarQueryParameter['coupon_code'] = couponCode;
            }

            if (insuranceRenterPolicyId !== undefined) {
                localVarQueryParameter['insurance_renter_policy_id'] = insuranceRenterPolicyId;
            }

            if (addons) {
                localVarQueryParameter['addons'] = addons.join(COLLECTION_FORMATS.csv);
            }

            if (initializationSource !== undefined) {
                localVarQueryParameter['initialization_source'] = initializationSource;
            }

            if (displayedPage !== undefined) {
                localVarQueryParameter['displayed_page'] = displayedPage;
            }

            if (fullPricingShownToUser !== undefined) {
                localVarQueryParameter['full_pricing_shown_to_user'] = fullPricingShownToUser;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recalculate existing booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCalculatorGetRequest} body Trip calculator JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCalculatorPost: async (id: string, body: DomesticV2BookingsIdCalculatorGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdCalculatorPost', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('domesticV2BookingsIdCalculatorPost', 'body', body)
            const localVarPath = `/domestic/v2/bookings/{id}/calculator`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCancellationRefundPercentGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdCancellationRefundPercentGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/cancellation_refund_percent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Captain approves the booking.
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCaptainApprovePost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdCaptainApprovePost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/captain_approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Captain cancels the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCaptainCancelPost: async (id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdCaptainCancelPost', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('domesticV2BookingsIdCaptainCancelPost', 'body', body)
            const localVarPath = `/domestic/v2/bookings/{id}/captain_cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Captain declines the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCaptainDeclinePost: async (id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdCaptainDeclinePost', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('domesticV2BookingsIdCaptainDeclinePost', 'body', body)
            const localVarPath = `/domestic/v2/bookings/{id}/captain_decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checkout from an existing bookg.
         * @summary Create a checkout
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCheckoutPostRequest} [body] Booking Checkout JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCheckoutPost: async (id: string, body?: DomesticV2BookingsIdCheckoutPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdCheckoutPost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/checkout`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel a booking
         * @param {string} id Booking ID
         * @param {string} [category] Booking cancelation category
         * @param {string} [reasonText] Booking cancelation reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdDelete: async (id: string, category?: string, reasonText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdDelete', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (reasonText !== undefined) {
                localVarQueryParameter['reason_text'] = reasonText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdFuelReimbursementsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdFuelReimbursementsGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/fuel_reimbursements`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {any} [receipt] The receipt file upload
         * @param {number} [gallons] 
         * @param {number} [totalCost] 
         * @param {string} [noteToRenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdFuelReimbursementsPost: async (id: string, receipt?: any, gallons?: number, totalCost?: number, noteToRenter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdFuelReimbursementsPost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/fuel_reimbursements`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


            if (receipt !== undefined) { 
                localVarFormParams.append('receipt', receipt as any);
            }
    
            if (gallons !== undefined) { 
                localVarFormParams.append('gallons', gallons as any);
            }
    
            if (totalCost !== undefined) { 
                localVarFormParams.append('total_cost', totalCost as any);
            }
    
            if (noteToRenter !== undefined) { 
                localVarFormParams.append('note_to_renter', noteToRenter as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get booking details
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a pre-approved or initialized Booking
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdInquiryPatchRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdInquiryPatch: async (id: string, body?: DomesticV2BookingsIdInquiryPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdInquiryPatch', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/inquiry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of Booking messages
         * @param {string} id Booking ID
         * @param {number} [perPage] Messages per page
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdMessagesGet: async (id: string, perPage?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdMessagesGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdMessagesGetRequest1} [body] Messages json payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdMessagesPatch: async (id: string, body?: DomesticV2BookingsIdMessagesGetRequest1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdMessagesPatch', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdMessagesGetRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdMessagesPost: async (id: string, body?: DomesticV2BookingsIdMessagesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdMessagesPost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/messages`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdModificationsGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/modifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsModificationIdAcceptPost: async (id: string, modificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdModificationsModificationIdAcceptPost', 'id', id)
            // verify required parameter 'modificationId' is not null or undefined
            assertParamExists('domesticV2BookingsIdModificationsModificationIdAcceptPost', 'modificationId', modificationId)
            const localVarPath = `/domestic/v2/bookings/{id}/modifications/{modification_id}/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"modification_id"}}`, encodeURIComponent(String(modificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsModificationIdDeclinePost: async (id: string, modificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdModificationsModificationIdDeclinePost', 'id', id)
            // verify required parameter 'modificationId' is not null or undefined
            assertParamExists('domesticV2BookingsIdModificationsModificationIdDeclinePost', 'modificationId', modificationId)
            const localVarPath = `/domestic/v2/bookings/{id}/modifications/{modification_id}/decline`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"modification_id"}}`, encodeURIComponent(String(modificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete modification
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsModificationIdDelete: async (id: string, modificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdModificationsModificationIdDelete', 'id', id)
            // verify required parameter 'modificationId' is not null or undefined
            assertParamExists('domesticV2BookingsIdModificationsModificationIdDelete', 'modificationId', modificationId)
            const localVarPath = `/domestic/v2/bookings/{id}/modifications/{modification_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"modification_id"}}`, encodeURIComponent(String(modificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdModificationsGetRequest} [body] Booking modification JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsPost: async (id: string, body?: DomesticV2BookingsIdModificationsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdModificationsPost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/modifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of captains near the boat.
         * @param {string} id Booking ID
         * @param {number} [page] The page number of captains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdNearCaptainsGet: async (id: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdNearCaptainsGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/near_captains`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The Onbaord Document PDF
         * @param {string} id Booking ID
         * @param {string} [token] The guest user token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdOnboardDocumentGet: async (id: string, token?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdOnboardDocumentGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/onboard_document`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPassengerShortCodesPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdPassengerShortCodesPost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/passenger_short_codes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the Booking pre-approval
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPreApprovalDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdPreApprovalDelete', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/pre_approval`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Booking pre-approval
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdPreApprovalDeleteRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPreApprovalPost: async (id: string, body?: DomesticV2BookingsIdPreApprovalDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdPreApprovalPost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/pre_approval`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPrerequisitesGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdPrerequisitesGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/prerequisites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get booking pricing details
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPricingGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdPricingGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/pricing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit a review for a Booking 
         * @param {string} id 
         * @param {DomesticV2BookingsIdReviewsPostRequest} [reviewBody] Review text and rating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdReviewsPost: async (id: string, reviewBody?: DomesticV2BookingsIdReviewsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdReviewsPost', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/reviews`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Booking Details for Passengers
         * @param {string} id Booking ID
         * @param {string} [shortCode] It is not required when the passenger is logged in and already has access to the booking invitation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdSharedDetailsGet: async (id: string, shortCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2BookingsIdSharedDetailsGet', 'id', id)
            const localVarPath = `/domestic/v2/bookings/{id}/shared_details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)

            if (shortCode !== undefined) {
                localVarQueryParameter['short_code'] = shortCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a booking for the provided parameter set if the client has the appropriate state to do so.
         * @summary Create an inquiry
         * @param {DomesticV2BookingsInquiryPostRequest} [body] Booking Request JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsInquiryPost: async (body?: DomesticV2BookingsInquiryPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/bookings/inquiry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get booking actions
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3BookingsIdActionsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV3BookingsIdActionsGet', 'id', id)
            const localVarPath = `/domestic/v3/bookings/{id}/actions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Checkout for the provided @Booking@ parameter set iff the client has the appropriate state to do so. A credit-card-id is expected to provide a means for authorising a payment. 
         * @summary Create a checkout
         * @param {DomesticV2BookingsCheckoutPostRequest} [body] Booking Checkout JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsCheckoutPost(body?: DomesticV2BookingsCheckoutPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsCheckoutPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} packageId 
         * @param {string} duration 
         * @param {string} tripStart 
         * @param {string} tripFinish 
         * @param {string} tripTime 
         * @param {string} [bookingId] 
         * @param {number} [passengers] 
         * @param {boolean} [purchasingInsurance] Required if package is using Buoy insurance
         * @param {string} [insuranceRenterPolicyId] Required if package is using Buoy insurance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsCheckoutPrerequisitesGet(packageId: string, duration: string, tripStart: string, tripFinish: string, tripTime: string, bookingId?: string, passengers?: number, purchasingInsurance?: boolean, insuranceRenterPolicyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsCheckoutPrerequisitesGet(packageId, duration, tripStart, tripFinish, tripTime, bookingId, passengers, purchasingInsurance, insuranceRenterPolicyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Start booking trip
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdAboardPatch(id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdAboardPatch(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Booking approval
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdApprovalPostRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdApprovalPost(id: string, body?: DomesticV2BookingsIdApprovalPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdApprovalPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * End booking trip
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdAshorePatch(id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdAshorePatch(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Assigns captain to the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAssignCaptainPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdAssignCaptainPost(id: string, body: DomesticV2BookingsIdAssignCaptainPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BookingsIdAssignCaptainPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdAssignCaptainPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recalculate existing booking. For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
         * @param {string} id Booking ID
         * @param {string} [tripStart] ISO 8601 date
         * @param {string} [tripFinish] ISO 8601 date
         * @param {number} [passengers] 
         * @param {number} [specialOfferPrice] Special offer price in cents
         * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
         * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
         * @param {Array<string>} [addons] Array of objects currently not supported, instead of string insert object with boat_add_on_id (string), quantity (number)
         * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
         * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
         * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdCalculatorGet(id: string, tripStart?: string, tripFinish?: string, passengers?: number, specialOfferPrice?: number, couponCode?: string, insuranceRenterPolicyId?: string, addons?: Array<string>, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdCalculatorGet(id, tripStart, tripFinish, passengers, specialOfferPrice, couponCode, insuranceRenterPolicyId, addons, initializationSource, displayedPage, fullPricingShownToUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recalculate existing booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCalculatorGetRequest} body Trip calculator JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdCalculatorPost(id: string, body: DomesticV2BookingsIdCalculatorGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdCalculatorPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdCancellationRefundPercentGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BookingsIdCancellationRefundPercentGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdCancellationRefundPercentGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Captain approves the booking.
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdCaptainApprovePost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdCaptainApprovePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Captain cancels the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdCaptainCancelPost(id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdCaptainCancelPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Captain declines the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdCaptainDeclinePost(id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdCaptainDeclinePost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checkout from an existing bookg.
         * @summary Create a checkout
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCheckoutPostRequest} [body] Booking Checkout JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdCheckoutPost(id: string, body?: DomesticV2BookingsIdCheckoutPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdCheckoutPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancel a booking
         * @param {string} id Booking ID
         * @param {string} [category] Booking cancelation category
         * @param {string} [reasonText] Booking cancelation reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdDelete(id: string, category?: string, reasonText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdDelete(id, category, reasonText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdFuelReimbursementsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingFuelReimbursement>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdFuelReimbursementsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {any} [receipt] The receipt file upload
         * @param {number} [gallons] 
         * @param {number} [totalCost] 
         * @param {string} [noteToRenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdFuelReimbursementsPost(id: string, receipt?: any, gallons?: number, totalCost?: number, noteToRenter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdFuelReimbursementsPost(id, receipt, gallons, totalCost, noteToRenter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get booking details
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a pre-approved or initialized Booking
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdInquiryPatchRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdInquiryPatch(id: string, body?: DomesticV2BookingsIdInquiryPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdInquiryPatch(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of Booking messages
         * @param {string} id Booking ID
         * @param {number} [perPage] Messages per page
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdMessagesGet(id: string, perPage?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BookingsIdMessagesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdMessagesGet(id, perPage, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdMessagesGetRequest1} [body] Messages json payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdMessagesPatch(id: string, body?: DomesticV2BookingsIdMessagesGetRequest1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdMessagesPatch(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdMessagesGetRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdMessagesPost(id: string, body?: DomesticV2BookingsIdMessagesGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdMessagesPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdModificationsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingModification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdModificationsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdModificationsModificationIdAcceptPost(id: string, modificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdModificationsModificationIdAcceptPost(id, modificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdModificationsModificationIdDeclinePost(id: string, modificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdModificationsModificationIdDeclinePost(id, modificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete modification
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdModificationsModificationIdDelete(id: string, modificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdModificationsModificationIdDelete(id, modificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdModificationsGetRequest} [body] Booking modification JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdModificationsPost(id: string, body?: DomesticV2BookingsIdModificationsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingModification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdModificationsPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of captains near the boat.
         * @param {string} id Booking ID
         * @param {number} [page] The page number of captains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdNearCaptainsGet(id: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomesticV2BookingsIdNearCaptainsGet200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdNearCaptainsGet(id, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The Onbaord Document PDF
         * @param {string} id Booking ID
         * @param {string} [token] The guest user token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdOnboardDocumentGet(id: string, token?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdOnboardDocumentGet(id, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdPassengerShortCodesPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BookingsIdPassengerShortCodesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdPassengerShortCodesPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the Booking pre-approval
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdPreApprovalDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdPreApprovalDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Booking pre-approval
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdPreApprovalDeleteRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdPreApprovalPost(id: string, body?: DomesticV2BookingsIdPreApprovalDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdPreApprovalPost(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdPrerequisitesGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2BookingsIdPrerequisitesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdPrerequisitesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get booking pricing details
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdPricingGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingPricing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdPricingGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit a review for a Booking 
         * @param {string} id 
         * @param {DomesticV2BookingsIdReviewsPostRequest} [reviewBody] Review text and rating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdReviewsPost(id: string, reviewBody?: DomesticV2BookingsIdReviewsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdReviewsPost(id, reviewBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Booking Details for Passengers
         * @param {string} id Booking ID
         * @param {string} [shortCode] It is not required when the passenger is logged in and already has access to the booking invitation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsIdSharedDetailsGet(id: string, shortCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsIdSharedDetailsGet(id, shortCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a booking for the provided parameter set if the client has the appropriate state to do so.
         * @summary Create an inquiry
         * @param {DomesticV2BookingsInquiryPostRequest} [body] Booking Request JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2BookingsInquiryPost(body?: DomesticV2BookingsInquiryPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2BookingsInquiryPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get booking actions
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV3BookingsIdActionsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingActions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV3BookingsIdActionsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsApiFp(configuration)
    return {
        /**
         * Create a Checkout for the provided @Booking@ parameter set iff the client has the appropriate state to do so. A credit-card-id is expected to provide a means for authorising a payment. 
         * @summary Create a checkout
         * @param {DomesticV2BookingsCheckoutPostRequest} [body] Booking Checkout JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsCheckoutPost(body?: DomesticV2BookingsCheckoutPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsCheckoutPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} packageId 
         * @param {string} duration 
         * @param {string} tripStart 
         * @param {string} tripFinish 
         * @param {string} tripTime 
         * @param {string} [bookingId] 
         * @param {number} [passengers] 
         * @param {boolean} [purchasingInsurance] Required if package is using Buoy insurance
         * @param {string} [insuranceRenterPolicyId] Required if package is using Buoy insurance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsCheckoutPrerequisitesGet(packageId: string, duration: string, tripStart: string, tripFinish: string, tripTime: string, bookingId?: string, passengers?: number, purchasingInsurance?: boolean, insuranceRenterPolicyId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsCheckoutPrerequisitesGet(packageId, duration, tripStart, tripFinish, tripTime, bookingId, passengers, purchasingInsurance, insuranceRenterPolicyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Start booking trip
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdAboardPatch(id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdAboardPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Booking approval
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdApprovalPostRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdApprovalPost(id: string, body?: DomesticV2BookingsIdApprovalPostRequest, options?: any): AxiosPromise<Booking> {
            return localVarFp.domesticV2BookingsIdApprovalPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * End booking trip
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdAshorePatch(id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdAshorePatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Assigns captain to the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdAssignCaptainPostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdAssignCaptainPost(id: string, body: DomesticV2BookingsIdAssignCaptainPostRequest, options?: any): AxiosPromise<DomesticV2BookingsIdAssignCaptainPost200Response> {
            return localVarFp.domesticV2BookingsIdAssignCaptainPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Recalculate existing booking. For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
         * @param {string} id Booking ID
         * @param {string} [tripStart] ISO 8601 date
         * @param {string} [tripFinish] ISO 8601 date
         * @param {number} [passengers] 
         * @param {number} [specialOfferPrice] Special offer price in cents
         * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
         * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
         * @param {Array<string>} [addons] Array of objects currently not supported, instead of string insert object with boat_add_on_id (string), quantity (number)
         * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
         * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
         * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCalculatorGet(id: string, tripStart?: string, tripFinish?: string, passengers?: number, specialOfferPrice?: number, couponCode?: string, insuranceRenterPolicyId?: string, addons?: Array<string>, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options?: any): AxiosPromise<Booking> {
            return localVarFp.domesticV2BookingsIdCalculatorGet(id, tripStart, tripFinish, passengers, specialOfferPrice, couponCode, insuranceRenterPolicyId, addons, initializationSource, displayedPage, fullPricingShownToUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Recalculate existing booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCalculatorGetRequest} body Trip calculator JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCalculatorPost(id: string, body: DomesticV2BookingsIdCalculatorGetRequest, options?: any): AxiosPromise<Booking> {
            return localVarFp.domesticV2BookingsIdCalculatorPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCancellationRefundPercentGet(id: string, options?: any): AxiosPromise<DomesticV2BookingsIdCancellationRefundPercentGet200Response> {
            return localVarFp.domesticV2BookingsIdCancellationRefundPercentGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Captain approves the booking.
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCaptainApprovePost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdCaptainApprovePost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Captain cancels the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCaptainCancelPost(id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdCaptainCancelPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Captain declines the booking.
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCaptainDeclinePost(id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdCaptainDeclinePost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Checkout from an existing bookg.
         * @summary Create a checkout
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdCheckoutPostRequest} [body] Booking Checkout JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdCheckoutPost(id: string, body?: DomesticV2BookingsIdCheckoutPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdCheckoutPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel a booking
         * @param {string} id Booking ID
         * @param {string} [category] Booking cancelation category
         * @param {string} [reasonText] Booking cancelation reason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdDelete(id: string, category?: string, reasonText?: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdDelete(id, category, reasonText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdFuelReimbursementsGet(id: string, options?: any): AxiosPromise<Array<BookingFuelReimbursement>> {
            return localVarFp.domesticV2BookingsIdFuelReimbursementsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {any} [receipt] The receipt file upload
         * @param {number} [gallons] 
         * @param {number} [totalCost] 
         * @param {string} [noteToRenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdFuelReimbursementsPost(id: string, receipt?: any, gallons?: number, totalCost?: number, noteToRenter?: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdFuelReimbursementsPost(id, receipt, gallons, totalCost, noteToRenter, options).then((request) => request(axios, basePath));
        },
        /**
         * Get booking details
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdGet(id: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.domesticV2BookingsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a pre-approved or initialized Booking
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdInquiryPatchRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdInquiryPatch(id: string, body?: DomesticV2BookingsIdInquiryPatchRequest, options?: any): AxiosPromise<Booking> {
            return localVarFp.domesticV2BookingsIdInquiryPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * List of Booking messages
         * @param {string} id Booking ID
         * @param {number} [perPage] Messages per page
         * @param {number} [page] The page of results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdMessagesGet(id: string, perPage?: number, page?: number, options?: any): AxiosPromise<DomesticV2BookingsIdMessagesGet200Response> {
            return localVarFp.domesticV2BookingsIdMessagesGet(id, perPage, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdMessagesGetRequest1} [body] Messages json payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdMessagesPatch(id: string, body?: DomesticV2BookingsIdMessagesGetRequest1, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdMessagesPatch(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdMessagesGetRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdMessagesPost(id: string, body?: DomesticV2BookingsIdMessagesGetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdMessagesPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsGet(id: string, options?: any): AxiosPromise<Array<BookingModification>> {
            return localVarFp.domesticV2BookingsIdModificationsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsModificationIdAcceptPost(id: string, modificationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdModificationsModificationIdAcceptPost(id, modificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsModificationIdDeclinePost(id: string, modificationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdModificationsModificationIdDeclinePost(id, modificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete modification
         * @param {string} id Booking ID
         * @param {string} modificationId Booking Modification ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsModificationIdDelete(id: string, modificationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdModificationsModificationIdDelete(id, modificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdModificationsGetRequest} [body] Booking modification JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdModificationsPost(id: string, body?: DomesticV2BookingsIdModificationsGetRequest, options?: any): AxiosPromise<BookingModification> {
            return localVarFp.domesticV2BookingsIdModificationsPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * List of captains near the boat.
         * @param {string} id Booking ID
         * @param {number} [page] The page number of captains
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdNearCaptainsGet(id: string, page?: number, options?: any): AxiosPromise<Array<DomesticV2BookingsIdNearCaptainsGet200ResponseInner>> {
            return localVarFp.domesticV2BookingsIdNearCaptainsGet(id, page, options).then((request) => request(axios, basePath));
        },
        /**
         * The Onbaord Document PDF
         * @param {string} id Booking ID
         * @param {string} [token] The guest user token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdOnboardDocumentGet(id: string, token?: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdOnboardDocumentGet(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPassengerShortCodesPost(id: string, options?: any): AxiosPromise<DomesticV2BookingsIdPassengerShortCodesPost200Response> {
            return localVarFp.domesticV2BookingsIdPassengerShortCodesPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the Booking pre-approval
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPreApprovalDelete(id: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.domesticV2BookingsIdPreApprovalDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Booking pre-approval
         * @param {string} id Booking ID
         * @param {DomesticV2BookingsIdPreApprovalDeleteRequest} [body] Booking approval JSON payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPreApprovalPost(id: string, body?: DomesticV2BookingsIdPreApprovalDeleteRequest, options?: any): AxiosPromise<Booking> {
            return localVarFp.domesticV2BookingsIdPreApprovalPost(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPrerequisitesGet(id: string, options?: any): AxiosPromise<DomesticV2BookingsIdPrerequisitesGet200Response> {
            return localVarFp.domesticV2BookingsIdPrerequisitesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get booking pricing details
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdPricingGet(id: string, options?: any): AxiosPromise<BookingPricing> {
            return localVarFp.domesticV2BookingsIdPricingGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit a review for a Booking 
         * @param {string} id 
         * @param {DomesticV2BookingsIdReviewsPostRequest} [reviewBody] Review text and rating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdReviewsPost(id: string, reviewBody?: DomesticV2BookingsIdReviewsPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsIdReviewsPost(id, reviewBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Booking Details for Passengers
         * @param {string} id Booking ID
         * @param {string} [shortCode] It is not required when the passenger is logged in and already has access to the booking invitation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsIdSharedDetailsGet(id: string, shortCode?: string, options?: any): AxiosPromise<SharedDetails> {
            return localVarFp.domesticV2BookingsIdSharedDetailsGet(id, shortCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a booking for the provided parameter set if the client has the appropriate state to do so.
         * @summary Create an inquiry
         * @param {DomesticV2BookingsInquiryPostRequest} [body] Booking Request JSON Payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2BookingsInquiryPost(body?: DomesticV2BookingsInquiryPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2BookingsInquiryPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get booking actions
         * @param {string} id Booking ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV3BookingsIdActionsGet(id: string, options?: any): AxiosPromise<BookingActions> {
            return localVarFp.domesticV3BookingsIdActionsGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingsApi - interface
 * @export
 * @interface BookingsApi
 */
export interface BookingsApiInterface {
    /**
     * Create a Checkout for the provided @Booking@ parameter set iff the client has the appropriate state to do so. A credit-card-id is expected to provide a means for authorising a payment. 
     * @summary Create a checkout
     * @param {DomesticV2BookingsCheckoutPostRequest} [body] Booking Checkout JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsCheckoutPost(body?: DomesticV2BookingsCheckoutPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} packageId 
     * @param {string} duration 
     * @param {string} tripStart 
     * @param {string} tripFinish 
     * @param {string} tripTime 
     * @param {string} [bookingId] 
     * @param {number} [passengers] 
     * @param {boolean} [purchasingInsurance] Required if package is using Buoy insurance
     * @param {string} [insuranceRenterPolicyId] Required if package is using Buoy insurance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsCheckoutPrerequisitesGet(packageId: string, duration: string, tripStart: string, tripFinish: string, tripTime: string, bookingId?: string, passengers?: number, purchasingInsurance?: boolean, insuranceRenterPolicyId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Start booking trip
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdAboardPatch(id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Creates a Booking approval
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdApprovalPostRequest} [body] Booking approval JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdApprovalPost(id: string, body?: DomesticV2BookingsIdApprovalPostRequest, options?: AxiosRequestConfig): AxiosPromise<Booking>;

    /**
     * End booking trip
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdAshorePatch(id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Assigns captain to the booking.
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdAssignCaptainPostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdAssignCaptainPost(id: string, body: DomesticV2BookingsIdAssignCaptainPostRequest, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BookingsIdAssignCaptainPost200Response>;

    /**
     * Recalculate existing booking. For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
     * @param {string} id Booking ID
     * @param {string} [tripStart] ISO 8601 date
     * @param {string} [tripFinish] ISO 8601 date
     * @param {number} [passengers] 
     * @param {number} [specialOfferPrice] Special offer price in cents
     * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
     * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
     * @param {Array<string>} [addons] Array of objects currently not supported, instead of string insert object with boat_add_on_id (string), quantity (number)
     * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
     * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
     * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdCalculatorGet(id: string, tripStart?: string, tripFinish?: string, passengers?: number, specialOfferPrice?: number, couponCode?: string, insuranceRenterPolicyId?: string, addons?: Array<string>, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options?: AxiosRequestConfig): AxiosPromise<Booking>;

    /**
     * Recalculate existing booking.
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdCalculatorGetRequest} body Trip calculator JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdCalculatorPost(id: string, body: DomesticV2BookingsIdCalculatorGetRequest, options?: AxiosRequestConfig): AxiosPromise<Booking>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdCancellationRefundPercentGet(id: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BookingsIdCancellationRefundPercentGet200Response>;

    /**
     * Captain approves the booking.
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdCaptainApprovePost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Captain cancels the booking.
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdCaptainCancelPost(id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Captain declines the booking.
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdCaptainDeclinePost(id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Checkout from an existing bookg.
     * @summary Create a checkout
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdCheckoutPostRequest} [body] Booking Checkout JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdCheckoutPost(id: string, body?: DomesticV2BookingsIdCheckoutPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Cancel a booking
     * @param {string} id Booking ID
     * @param {string} [category] Booking cancelation category
     * @param {string} [reasonText] Booking cancelation reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdDelete(id: string, category?: string, reasonText?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdFuelReimbursementsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<BookingFuelReimbursement>>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {any} [receipt] The receipt file upload
     * @param {number} [gallons] 
     * @param {number} [totalCost] 
     * @param {string} [noteToRenter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdFuelReimbursementsPost(id: string, receipt?: any, gallons?: number, totalCost?: number, noteToRenter?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get booking details
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Booking>;

    /**
     * Updates a pre-approved or initialized Booking
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdInquiryPatchRequest} [body] Booking approval JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdInquiryPatch(id: string, body?: DomesticV2BookingsIdInquiryPatchRequest, options?: AxiosRequestConfig): AxiosPromise<Booking>;

    /**
     * List of Booking messages
     * @param {string} id Booking ID
     * @param {number} [perPage] Messages per page
     * @param {number} [page] The page of results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdMessagesGet(id: string, perPage?: number, page?: number, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BookingsIdMessagesGet200Response>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdMessagesGetRequest1} [body] Messages json payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdMessagesPatch(id: string, body?: DomesticV2BookingsIdMessagesGetRequest1, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdMessagesGetRequest} [body] Booking approval JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdMessagesPost(id: string, body?: DomesticV2BookingsIdMessagesGetRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdModificationsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<BookingModification>>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {string} modificationId Booking Modification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdModificationsModificationIdAcceptPost(id: string, modificationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {string} modificationId Booking Modification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdModificationsModificationIdDeclinePost(id: string, modificationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Delete modification
     * @param {string} id Booking ID
     * @param {string} modificationId Booking Modification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdModificationsModificationIdDelete(id: string, modificationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdModificationsGetRequest} [body] Booking modification JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdModificationsPost(id: string, body?: DomesticV2BookingsIdModificationsGetRequest, options?: AxiosRequestConfig): AxiosPromise<BookingModification>;

    /**
     * List of captains near the boat.
     * @param {string} id Booking ID
     * @param {number} [page] The page number of captains
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdNearCaptainsGet(id: string, page?: number, options?: AxiosRequestConfig): AxiosPromise<Array<DomesticV2BookingsIdNearCaptainsGet200ResponseInner>>;

    /**
     * The Onbaord Document PDF
     * @param {string} id Booking ID
     * @param {string} [token] The guest user token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdOnboardDocumentGet(id: string, token?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdPassengerShortCodesPost(id: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BookingsIdPassengerShortCodesPost200Response>;

    /**
     * Deletes the Booking pre-approval
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdPreApprovalDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<Booking>;

    /**
     * Creates a Booking pre-approval
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdPreApprovalDeleteRequest} [body] Booking approval JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdPreApprovalPost(id: string, body?: DomesticV2BookingsIdPreApprovalDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<Booking>;

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdPrerequisitesGet(id: string, options?: AxiosRequestConfig): AxiosPromise<DomesticV2BookingsIdPrerequisitesGet200Response>;

    /**
     * Get booking pricing details
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdPricingGet(id: string, options?: AxiosRequestConfig): AxiosPromise<BookingPricing>;

    /**
     * Submit a review for a Booking 
     * @param {string} id 
     * @param {DomesticV2BookingsIdReviewsPostRequest} [reviewBody] Review text and rating
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdReviewsPost(id: string, reviewBody?: DomesticV2BookingsIdReviewsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Booking Details for Passengers
     * @param {string} id Booking ID
     * @param {string} [shortCode] It is not required when the passenger is logged in and already has access to the booking invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsIdSharedDetailsGet(id: string, shortCode?: string, options?: AxiosRequestConfig): AxiosPromise<SharedDetails>;

    /**
     * Create a booking for the provided parameter set if the client has the appropriate state to do so.
     * @summary Create an inquiry
     * @param {DomesticV2BookingsInquiryPostRequest} [body] Booking Request JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV2BookingsInquiryPost(body?: DomesticV2BookingsInquiryPostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get booking actions
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApiInterface
     */
    domesticV3BookingsIdActionsGet(id: string, options?: AxiosRequestConfig): AxiosPromise<BookingActions>;

}

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI implements BookingsApiInterface {
    /**
     * Create a Checkout for the provided @Booking@ parameter set iff the client has the appropriate state to do so. A credit-card-id is expected to provide a means for authorising a payment. 
     * @summary Create a checkout
     * @param {DomesticV2BookingsCheckoutPostRequest} [body] Booking Checkout JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsCheckoutPost(body?: DomesticV2BookingsCheckoutPostRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsCheckoutPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} packageId 
     * @param {string} duration 
     * @param {string} tripStart 
     * @param {string} tripFinish 
     * @param {string} tripTime 
     * @param {string} [bookingId] 
     * @param {number} [passengers] 
     * @param {boolean} [purchasingInsurance] Required if package is using Buoy insurance
     * @param {string} [insuranceRenterPolicyId] Required if package is using Buoy insurance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsCheckoutPrerequisitesGet(packageId: string, duration: string, tripStart: string, tripFinish: string, tripTime: string, bookingId?: string, passengers?: number, purchasingInsurance?: boolean, insuranceRenterPolicyId?: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsCheckoutPrerequisitesGet(packageId, duration, tripStart, tripFinish, tripTime, bookingId, passengers, purchasingInsurance, insuranceRenterPolicyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Start booking trip
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdAboardPatch(id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdAboardPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Booking approval
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdApprovalPostRequest} [body] Booking approval JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdApprovalPost(id: string, body?: DomesticV2BookingsIdApprovalPostRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdApprovalPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * End booking trip
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdAboardPatchRequest} [body] Booking aboard JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdAshorePatch(id: string, body?: DomesticV2BookingsIdAboardPatchRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdAshorePatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Assigns captain to the booking.
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdAssignCaptainPostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdAssignCaptainPost(id: string, body: DomesticV2BookingsIdAssignCaptainPostRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdAssignCaptainPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recalculate existing booking. For add-ons, we need to include additional query params ie. \"&add_ons[][boat_add_on_id]=2&add_ons[][quantity]=1\" (because the Swaggerhub api doc doesn\'t support the array of objects query params, refrence https://community.smartbear.com/t5/SwaggerHub-Questions/Swagger-creating-the-query-parameters-products-i-sku/td-p/209172)
     * @param {string} id Booking ID
     * @param {string} [tripStart] ISO 8601 date
     * @param {string} [tripFinish] ISO 8601 date
     * @param {number} [passengers] 
     * @param {number} [specialOfferPrice] Special offer price in cents
     * @param {string} [couponCode] The coupon code to discount rental price. Overrides Boating Credits
     * @param {string} [insuranceRenterPolicyId] The unique id for the insurance renter policy which is or will be attached to the booking
     * @param {Array<string>} [addons] Array of objects currently not supported, instead of string insert object with boat_add_on_id (string), quantity (number)
     * @param {'instant' | 'checkout' | 'conversation'} [initializationSource] Where the initialization of the booking occured.
     * @param {'pdp' | 'conversation' | 'checkout' | 'add_ons'} [displayedPage] Which page the calculate was called on
     * @param {boolean} [fullPricingShownToUser] Whether full pricing was shown to the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdCalculatorGet(id: string, tripStart?: string, tripFinish?: string, passengers?: number, specialOfferPrice?: number, couponCode?: string, insuranceRenterPolicyId?: string, addons?: Array<string>, initializationSource?: 'instant' | 'checkout' | 'conversation', displayedPage?: 'pdp' | 'conversation' | 'checkout' | 'add_ons', fullPricingShownToUser?: boolean, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdCalculatorGet(id, tripStart, tripFinish, passengers, specialOfferPrice, couponCode, insuranceRenterPolicyId, addons, initializationSource, displayedPage, fullPricingShownToUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recalculate existing booking.
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdCalculatorGetRequest} body Trip calculator JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdCalculatorPost(id: string, body: DomesticV2BookingsIdCalculatorGetRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdCalculatorPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdCancellationRefundPercentGet(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdCancellationRefundPercentGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Captain approves the booking.
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdCaptainApprovePost(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdCaptainApprovePost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Captain cancels the booking.
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdCaptainCancelPost(id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdCaptainCancelPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Captain declines the booking.
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdCaptainDeclinePostRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdCaptainDeclinePost(id: string, body: DomesticV2BookingsIdCaptainDeclinePostRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdCaptainDeclinePost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checkout from an existing bookg.
     * @summary Create a checkout
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdCheckoutPostRequest} [body] Booking Checkout JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdCheckoutPost(id: string, body?: DomesticV2BookingsIdCheckoutPostRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdCheckoutPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel a booking
     * @param {string} id Booking ID
     * @param {string} [category] Booking cancelation category
     * @param {string} [reasonText] Booking cancelation reason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdDelete(id: string, category?: string, reasonText?: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdDelete(id, category, reasonText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdFuelReimbursementsGet(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdFuelReimbursementsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {any} [receipt] The receipt file upload
     * @param {number} [gallons] 
     * @param {number} [totalCost] 
     * @param {string} [noteToRenter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdFuelReimbursementsPost(id: string, receipt?: any, gallons?: number, totalCost?: number, noteToRenter?: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdFuelReimbursementsPost(id, receipt, gallons, totalCost, noteToRenter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get booking details
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdGet(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a pre-approved or initialized Booking
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdInquiryPatchRequest} [body] Booking approval JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdInquiryPatch(id: string, body?: DomesticV2BookingsIdInquiryPatchRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdInquiryPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of Booking messages
     * @param {string} id Booking ID
     * @param {number} [perPage] Messages per page
     * @param {number} [page] The page of results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdMessagesGet(id: string, perPage?: number, page?: number, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdMessagesGet(id, perPage, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdMessagesGetRequest1} [body] Messages json payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdMessagesPatch(id: string, body?: DomesticV2BookingsIdMessagesGetRequest1, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdMessagesPatch(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdMessagesGetRequest} [body] Booking approval JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdMessagesPost(id: string, body?: DomesticV2BookingsIdMessagesGetRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdMessagesPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdModificationsGet(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdModificationsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {string} modificationId Booking Modification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdModificationsModificationIdAcceptPost(id: string, modificationId: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdModificationsModificationIdAcceptPost(id, modificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {string} modificationId Booking Modification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdModificationsModificationIdDeclinePost(id: string, modificationId: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdModificationsModificationIdDeclinePost(id, modificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete modification
     * @param {string} id Booking ID
     * @param {string} modificationId Booking Modification ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdModificationsModificationIdDelete(id: string, modificationId: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdModificationsModificationIdDelete(id, modificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdModificationsGetRequest} [body] Booking modification JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdModificationsPost(id: string, body?: DomesticV2BookingsIdModificationsGetRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdModificationsPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of captains near the boat.
     * @param {string} id Booking ID
     * @param {number} [page] The page number of captains
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdNearCaptainsGet(id: string, page?: number, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdNearCaptainsGet(id, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The Onbaord Document PDF
     * @param {string} id Booking ID
     * @param {string} [token] The guest user token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdOnboardDocumentGet(id: string, token?: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdOnboardDocumentGet(id, token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdPassengerShortCodesPost(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdPassengerShortCodesPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the Booking pre-approval
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdPreApprovalDelete(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdPreApprovalDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Booking pre-approval
     * @param {string} id Booking ID
     * @param {DomesticV2BookingsIdPreApprovalDeleteRequest} [body] Booking approval JSON payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdPreApprovalPost(id: string, body?: DomesticV2BookingsIdPreApprovalDeleteRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdPreApprovalPost(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdPrerequisitesGet(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdPrerequisitesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get booking pricing details
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdPricingGet(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdPricingGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit a review for a Booking 
     * @param {string} id 
     * @param {DomesticV2BookingsIdReviewsPostRequest} [reviewBody] Review text and rating
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdReviewsPost(id: string, reviewBody?: DomesticV2BookingsIdReviewsPostRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdReviewsPost(id, reviewBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Booking Details for Passengers
     * @param {string} id Booking ID
     * @param {string} [shortCode] It is not required when the passenger is logged in and already has access to the booking invitation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsIdSharedDetailsGet(id: string, shortCode?: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsIdSharedDetailsGet(id, shortCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a booking for the provided parameter set if the client has the appropriate state to do so.
     * @summary Create an inquiry
     * @param {DomesticV2BookingsInquiryPostRequest} [body] Booking Request JSON Payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV2BookingsInquiryPost(body?: DomesticV2BookingsInquiryPostRequest, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV2BookingsInquiryPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get booking actions
     * @param {string} id Booking ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public domesticV3BookingsIdActionsGet(id: string, options?: AxiosRequestConfig) {
        return BookingsApiFp(this.configuration).domesticV3BookingsIdActionsGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}
