import OpenApiConfiguration, { API_CLIENT_HEADERS } from 'api/OpenApiConfiguration';
import axiosConfig, { AxiosError, AxiosResponse } from 'axios';
import { useRouter } from 'next/router';
import { UseQueryOptions } from 'react-query';
import { getAsString } from 'helpers';
import {
  Booking,
  DomesticV2BookingsIdCancellationRefundPercentGet200Response,
  DomesticV2BookingsIdNearCaptainsGet200ResponseInner,
} from 'swagger/models';
import { useAuthQuery } from 'utils/queryHooks';
import { BookingsApi } from 'swagger/apis/bookings-api';
import { CreditCardsApi } from 'swagger/apis/credit-cards-api';
import AxiosConfig from 'api/AxiosConfig';

const bookingsApi = new BookingsApi(OpenApiConfiguration);
const creditCardsApi = new CreditCardsApi(OpenApiConfiguration);

export const useBookingTrip = (
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof bookingsApi.domesticV2BookingsIdGet>>, AxiosError<any, any>>,
    'queryKey' | 'queryFn'
  >,
  customBookingId: string = undefined
) => {
  const router = useRouter();
  const bookingId = customBookingId ?? getAsString(router.query.bookingId);

  const { data: { data: bookingTrip = {} } = {}, ...other } = useAuthQuery<AxiosResponse<Booking, any>>(
    ['bookingTrip', bookingId],
    () => bookingsApi.domesticV2BookingsIdGet(bookingId),
    {
      ...options,
      enabled: !!bookingId && (options?.enabled ?? true),
    }
  );
  return { bookingTrip, ...other };
};

export const useRefundQuery = (
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof bookingsApi.domesticV2BookingsIdCancellationRefundPercentGet>>,
      AxiosError<any, any>
    >,
    'queryKey' | 'queryFn' | 'enabled'
  >,
  customBookingId: string = undefined
) => {
  const router = useRouter();
  const bookingId = customBookingId ?? getAsString(router.query.bookingId);

  const { data: { data: refund = {} } = {}, ...other } = useAuthQuery<
    AxiosResponse<DomesticV2BookingsIdCancellationRefundPercentGet200Response, any>
  >(['refund', bookingId], () => bookingsApi.domesticV2BookingsIdCancellationRefundPercentGet(bookingId), {
    ...options,
    enabled: !!bookingId,
  });
  return { refund, ...other };
};

export const useNearCaptainsForBookingQuery = (
  bookingId: string,
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof bookingsApi.domesticV2BookingsIdNearCaptainsGet>>, AxiosError<any, any>>,
    'queryKey' | 'queryFn'
  >
) => {
  const { data: { data: nearCaptains = [] } = {}, ...other } = useAuthQuery<
    AxiosResponse<DomesticV2BookingsIdNearCaptainsGet200ResponseInner[], any>
  >(['nearCaptains', bookingId], () => bookingsApi.domesticV2BookingsIdNearCaptainsGet(bookingId), {
    ...options,
    enabled: !!bookingId && (options?.enabled ?? true),
  });
  return { nearCaptains, ...other };
};

export type CheckoutRequestGuardPayloadType = {
  booking_id?: string;
  package_id: string;
  trip_start: string;
  trip_time: string;
  trip_finish: string;
  passengers: number;
  duration: string;
  purchasingInsurance?: boolean;
  insuranceRenterPolicyId?: string;
  maxPassengers?: number;
};

export function useCheckoutRequestGuard(
  params: CheckoutRequestGuardPayloadType,
  options?: Omit<
    UseQueryOptions<
      Awaited<ReturnType<typeof bookingsApi.domesticV2BookingsCheckoutPrerequisitesGet>>,
      AxiosError<any, any>
    >,
    'queryKey' | 'queryFn'
  >
) {
  const {
    booking_id,
    package_id,
    trip_start,
    trip_time,
    trip_finish,
    passengers,
    duration,
    purchasingInsurance,
    insuranceRenterPolicyId,
  } = params ?? {};
  const { data: { data: checkoutRequestGuard = {} } = {}, ...other } = useAuthQuery(
    [
      'checkoutPrerequisites',
      booking_id,
      package_id,
      trip_start,
      trip_time,
      trip_finish,
      passengers,
      duration,
      purchasingInsurance,
      insuranceRenterPolicyId,
    ],
    () =>
      bookingsApi.domesticV2BookingsCheckoutPrerequisitesGet(
        package_id,
        duration,
        trip_start,
        trip_finish,
        trip_time,
        booking_id,
        passengers,
        purchasingInsurance,
        insuranceRenterPolicyId
      ),
    options
  );

  return { checkoutRequestGuard, ...other };
}

const axios = axiosConfig.create(AxiosConfig);

export function useCreditCards(
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof creditCardsApi.domesticV2MeCreditCardsGet>>, AxiosError<any, any>>,
    'queryKey' | 'queryFn'
  >
) {
  const { data: { data: creditCards = [] } = {}, ...other } = useAuthQuery(
    ['creditCards'],
    () =>
      axios.get('/domestic/v1/me/credit_cards', {
        headers: API_CLIENT_HEADERS,
      }),
    options
  );

  return { creditCards, ...other };
}
