/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreditCard } from '../models';
// @ts-ignore
import { DomesticV2MeCreditCardsGetRequest } from '../models';
// @ts-ignore
import { DomesticV2MeCreditCardsIdDeleteRequest } from '../models';
/**
 * CreditCardsApi - axios parameter creator
 * @export
 */
export const CreditCardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user credit cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/credit_cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete credit card
         * @param {string} id Credit card ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2MeCreditCardsIdDelete', 'id', id)
            const localVarPath = `/domestic/v2/me/credit_cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get credit card
         * @param {string} id Credit Card ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2MeCreditCardsIdGet', 'id', id)
            const localVarPath = `/domestic/v2/me/credit_cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update credit card
         * @param {string} id Credit Card ID
         * @param {DomesticV2MeCreditCardsIdDeleteRequest} [creditCard] Update credit card params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsIdPatch: async (id: string, creditCard?: DomesticV2MeCreditCardsIdDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('domesticV2MeCreditCardsIdPatch', 'id', id)
            const localVarPath = `/domestic/v2/me/credit_cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creditCard, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create credit card
         * @param {DomesticV2MeCreditCardsGetRequest} [stripeToken] To save user device data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsPost: async (stripeToken?: DomesticV2MeCreditCardsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/me/credit_cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stripeToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditCardsApi - functional programming interface
 * @export
 */
export const CreditCardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditCardsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user credit cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeCreditCardsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreditCard>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeCreditCardsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete credit card
         * @param {string} id Credit card ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeCreditCardsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeCreditCardsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get credit card
         * @param {string} id Credit Card ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeCreditCardsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeCreditCardsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update credit card
         * @param {string} id Credit Card ID
         * @param {DomesticV2MeCreditCardsIdDeleteRequest} [creditCard] Update credit card params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeCreditCardsIdPatch(id: string, creditCard?: DomesticV2MeCreditCardsIdDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeCreditCardsIdPatch(id, creditCard, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create credit card
         * @param {DomesticV2MeCreditCardsGetRequest} [stripeToken] To save user device data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2MeCreditCardsPost(stripeToken?: DomesticV2MeCreditCardsGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreditCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2MeCreditCardsPost(stripeToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CreditCardsApi - factory interface
 * @export
 */
export const CreditCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditCardsApiFp(configuration)
    return {
        /**
         * Get user credit cards
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsGet(options?: any): AxiosPromise<Array<CreditCard>> {
            return localVarFp.domesticV2MeCreditCardsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Delete credit card
         * @param {string} id Credit card ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.domesticV2MeCreditCardsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get credit card
         * @param {string} id Credit Card ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsIdGet(id: string, options?: any): AxiosPromise<CreditCard> {
            return localVarFp.domesticV2MeCreditCardsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update credit card
         * @param {string} id Credit Card ID
         * @param {DomesticV2MeCreditCardsIdDeleteRequest} [creditCard] Update credit card params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsIdPatch(id: string, creditCard?: DomesticV2MeCreditCardsIdDeleteRequest, options?: any): AxiosPromise<CreditCard> {
            return localVarFp.domesticV2MeCreditCardsIdPatch(id, creditCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Create credit card
         * @param {DomesticV2MeCreditCardsGetRequest} [stripeToken] To save user device data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2MeCreditCardsPost(stripeToken?: DomesticV2MeCreditCardsGetRequest, options?: any): AxiosPromise<CreditCard> {
            return localVarFp.domesticV2MeCreditCardsPost(stripeToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreditCardsApi - interface
 * @export
 * @interface CreditCardsApi
 */
export interface CreditCardsApiInterface {
    /**
     * Get user credit cards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApiInterface
     */
    domesticV2MeCreditCardsGet(options?: AxiosRequestConfig): AxiosPromise<Array<CreditCard>>;

    /**
     * Delete credit card
     * @param {string} id Credit card ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApiInterface
     */
    domesticV2MeCreditCardsIdDelete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Get credit card
     * @param {string} id Credit Card ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApiInterface
     */
    domesticV2MeCreditCardsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<CreditCard>;

    /**
     * Update credit card
     * @param {string} id Credit Card ID
     * @param {DomesticV2MeCreditCardsIdDeleteRequest} [creditCard] Update credit card params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApiInterface
     */
    domesticV2MeCreditCardsIdPatch(id: string, creditCard?: DomesticV2MeCreditCardsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCard>;

    /**
     * Create credit card
     * @param {DomesticV2MeCreditCardsGetRequest} [stripeToken] To save user device data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApiInterface
     */
    domesticV2MeCreditCardsPost(stripeToken?: DomesticV2MeCreditCardsGetRequest, options?: AxiosRequestConfig): AxiosPromise<CreditCard>;

}

/**
 * CreditCardsApi - object-oriented interface
 * @export
 * @class CreditCardsApi
 * @extends {BaseAPI}
 */
export class CreditCardsApi extends BaseAPI implements CreditCardsApiInterface {
    /**
     * Get user credit cards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApi
     */
    public domesticV2MeCreditCardsGet(options?: AxiosRequestConfig) {
        return CreditCardsApiFp(this.configuration).domesticV2MeCreditCardsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete credit card
     * @param {string} id Credit card ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApi
     */
    public domesticV2MeCreditCardsIdDelete(id: string, options?: AxiosRequestConfig) {
        return CreditCardsApiFp(this.configuration).domesticV2MeCreditCardsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get credit card
     * @param {string} id Credit Card ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApi
     */
    public domesticV2MeCreditCardsIdGet(id: string, options?: AxiosRequestConfig) {
        return CreditCardsApiFp(this.configuration).domesticV2MeCreditCardsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update credit card
     * @param {string} id Credit Card ID
     * @param {DomesticV2MeCreditCardsIdDeleteRequest} [creditCard] Update credit card params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApi
     */
    public domesticV2MeCreditCardsIdPatch(id: string, creditCard?: DomesticV2MeCreditCardsIdDeleteRequest, options?: AxiosRequestConfig) {
        return CreditCardsApiFp(this.configuration).domesticV2MeCreditCardsIdPatch(id, creditCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create credit card
     * @param {DomesticV2MeCreditCardsGetRequest} [stripeToken] To save user device data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditCardsApi
     */
    public domesticV2MeCreditCardsPost(stripeToken?: DomesticV2MeCreditCardsGetRequest, options?: AxiosRequestConfig) {
        return CreditCardsApiFp(this.configuration).domesticV2MeCreditCardsPost(stripeToken, options).then((request) => request(this.axios, this.basePath));
    }
}
