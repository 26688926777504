/* tslint:disable */
/* eslint-disable */
/**
 * Boatsetter API
 * Endpoints require an access token set via the `X-UserToken` header
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DomesticV2SiteGet200Response } from '../models';
/**
 * SiteApi - axios parameter creator
 * @export
 */
export const SiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns site data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2SiteGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/domestic/v2/site`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "password", ["read", "write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteApi - functional programming interface
 * @export
 */
export const SiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns site data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async domesticV2SiteGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomesticV2SiteGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.domesticV2SiteGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteApi - factory interface
 * @export
 */
export const SiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteApiFp(configuration)
    return {
        /**
         * Returns site data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        domesticV2SiteGet(options?: any): AxiosPromise<DomesticV2SiteGet200Response> {
            return localVarFp.domesticV2SiteGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteApi - interface
 * @export
 * @interface SiteApi
 */
export interface SiteApiInterface {
    /**
     * Returns site data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApiInterface
     */
    domesticV2SiteGet(options?: AxiosRequestConfig): AxiosPromise<DomesticV2SiteGet200Response>;

}

/**
 * SiteApi - object-oriented interface
 * @export
 * @class SiteApi
 * @extends {BaseAPI}
 */
export class SiteApi extends BaseAPI implements SiteApiInterface {
    /**
     * Returns site data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteApi
     */
    public domesticV2SiteGet(options?: AxiosRequestConfig) {
        return SiteApiFp(this.configuration).domesticV2SiteGet(options).then((request) => request(this.axios, this.basePath));
    }
}
